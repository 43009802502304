<template>
  <div class="header">
    <div class="header__logo">
      <a href="https://legales.exim.mobi/">
        <img style="width: 200px" src="../assets/logo-exim.jpg" />
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
};
</script>

<style lang="less">
.header {
  display: block;
  margin: 1rem 2.4rem;

  &__logo {
    position: relative;
    width: 100px;
    
    img {
      max-width: 100px;
      align-self: center;
    }

    &:after {
      content: '| Legales';
      font-size: 24px;
      position: absolute;
      right: -105px;
      top: 16px;
    }
  }
}
</style>
