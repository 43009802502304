<template>
  <div class="home">
    <Header />
    <a-tabs :activeKey="selectedTab" @change="onChange">
      <a-tab-pane v-for="item in legales" :key="item.slug" :tab="item.title">
        <router-view></router-view>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { trimPath }  from "../utils/helpers";
import Header from "../components/Header";

export default {
  name: "Home",
  components: {
    Header,
  },
  mounted() {
    this.onChange(trimPath(window.location.pathname))
  },
  methods: {
    onChange(key) {
      this.$store.commit("changeIndex", key);
      if (window.location.href == process.env.VUE_APP_URL && key == "/") {
        return;
      }
      this.$router.push(key);
    },
  },
  computed: {
    ...mapGetters({
      legales: "getLegales"
    }),
    selectedTab() {
      return this.$store.getters.selectedIndex;
    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
