<template>
  <div class="login">
    <a-row type="flex" justify="space-around" align="middle" style="height: 100vh;">
      <a-col :xs="{span: 20}" :sm="{span: 10}" :lg="{span: 6}">
        <a href="/">
          <img style="width: 150px" src="../../assets/logo-exim.jpg" />
        </a>

        <a-form-model
          ref="ruleForm"
          :model="loginForm"
          :rules="rules"
          @submit="handleSubmit"
          @submit.native.prevent
        >
          <h4>Sign in with your email</h4>

          <a-form-model-item ref="email" label="Email" prop="email">
            <a-input
              type="email"
              size="large"
              v-model.trim="loginForm.email"
              @blur=" () => $refs.email.onFieldBlur()"
              @change=" () => $refs.email.onFieldChange()"
            />
          </a-form-model-item>

          <a-form-model-item ref="password" label="Password" prop="password">
            <a-input
              type="password"
              placeholder="******"
              size="large"
              v-model.trim="loginForm.password"
              @blur="() => $refs.password.onFieldBlur()"
              @change="() => $refs.password.onFieldChange()"
            />
          </a-form-model-item>

          <a-button type="primary" size="large" html-type="submit" :loading="isLoading" block>Log In</a-button>

          <router-link to="/password-reset">Forgot password</router-link>
        </a-form-model>
      </a-col>
    </a-row>
  </div>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      isLoading: false,
      loginForm: {
        email: "",
        password: "",
      },
      rules: {
        email: [
          {
            type: "email",
            required: true,
            message: "Please enter valid email",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "Please enter a valid password",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    handleSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.isLoading = true;

          this.$store
            .dispatch("login", {
              email: this.loginForm.email,
              password: this.loginForm.password,
            })
            .then(() => {
              this.isLoading = false;
            })
            .catch((error) => {
              this.isLoading = false;
              console.log(error);
            });
        }
      });
    },
  },
};
</script>

<style lang="less">
.login {
  h4 {
    font-weight: 600;
    margin-top: 1rem;
  }
}
</style>
